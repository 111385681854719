import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ["query", "orders", "newquery", "chips", "tagContainer", "projectContainer", "storeContainer", "amountFields", "dateFields", "amount"]
  static values = { path: String }

  removePill(event) {
    const pill = event.target.closest(".pill");
    const searchType = pill.dataset.searchType;
    const searchValue = pill.dataset.searchValue;

    pill.remove();

    const searchHistory = JSON.parse(this.element.querySelector('input[name="search_history"]').value);

    if (searchHistory[searchType]) {
      searchHistory[searchType] = searchHistory[searchType].filter(value => value !== searchValue);

      if (searchHistory[searchType].length === 0) {
        delete searchHistory[searchType];
      }
    }

    this.element.querySelector('input[name="search_history"]').value = JSON.stringify(searchHistory);
    this.element.requestSubmit();
  }

  search() {
    // Typeahead search on the order search form
    const value = this.queryTarget.value
    console.log("order searching + " + value)
    Rails.ajax({
      type: "GET",
      url: `${this.pathValue}?query=${value}`,
      success: (_data, _status, xhr) => {
        this.ordersTarget.outerHTML = xhr.response
      }
    })
  }

  toggleField(container) {
    const newquery = container.querySelector('[data-order-search-target="newquery"]');
    const tagContainer = container.querySelector('[data-order-search-target="tagContainer"]');
    const projectContainer = container.querySelector('[data-order-search-target="projectContainer"]');
    const storeContainer = container.querySelector('[data-order-search-target="storeContainer"]');
    const amountfields = container.querySelector('[data-order-search-target="amountFields"]');
    const datefields = container.querySelector('[data-order-search-target="dateFields"]');

    newquery.style.display = 'none';
    tagContainer.style.display = 'none';
    projectContainer.style.display = 'none';
    storeContainer.style.display = 'none';
    amountfields.style.display = 'none';
    datefields.style.display = 'none';

    switch (event.target.value) {
      case 'tags':
        tagContainer.style.display = 'block';
        break;
      case 'project':
        projectContainer.style.display = 'block';
        break;
      case 'store':
        storeContainer.style.display = 'block';
        break;
      case 'total_amount':
        amountfields.style.display = 'block';
        break;
      case 'date_range':
        datefields.style.display = 'block';
        break;
      default:
        newquery.style.display = 'block';
    }
  }

  toggleSearchFields(event) {
    const container = event.target.closest('div').nextElementSibling;
    this.toggleField(container)
  }

  toggleSearchFieldsMobile(event) {
    const container = event.target.parentElement.parentElement.nextElementSibling.childNodes[1]
    this.toggleField(container)
  }

  restrictDecimals(event) {
    const value = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(value)) {
      event.target.value = value.slice(0, -1);
    }
  }

}
